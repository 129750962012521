<template>
  <div class = "c-faq-list">
  <div class = "c-faq-list__container">
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click="toggleActive(0)">
        <p class = "c-faq-list__title">How to activate 2FA on Sparrow?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[0].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[0].status == true}">
        <div class="c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text">What is 2FA and why is it important?</p><br />
          <p class = "c-faq-list__body-content-text c-faq-list__body-content-text-2fa">Two-factor authentication (2FA) is an extra layer of security that you can enable under your Security Settings. 2FA makes it more difficult for unauthorized users to access your account. You are strongly encouraged to enable 2FA for greater security.</p>
          <p class = "c-faq-list__body-content-text_bold">Activate 2FA in just 3 steps.</p><br />
          <p class = "c-faq-list__body-content-number-list">1. Login to your Sparrow account</p>
          <p class = "c-faq-list__body-content-number-list c-faq-list__body-content-margin20">2. Click on the “Security” tab at the left-hand side of the page</p>
          <img src="@/assets/images/faq-activate-1.svg" class="c-faq-list__body-content-img">
          <p class = "c-faq-list__body-content-number-list">1. Click the toggle button to enable 2FA</p>
          <li class = "c-faq-list__body-content-bullet-list c-faq-list__body-content-margin9"><span class = "c-faq-list__body-content-bullet-list-text">Follow the instructions shown on the webpage to continue the 2FA activation process</span></li>
          <img src="@/assets/images/faq-activate-2.svg" class="c-faq-list__body-content-img">
          <img src="@/assets/images/faq-activate-3.svg" class="c-faq-list__body-content-img">
          <p class = "c-faq-list__body-content-text">Activate now: <a href="https://www.sparrowexchange.com" target="_blank"><span class = "c-faq-list__body-content-text_url">https://www.sparrowexchange.com</span></a></p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(1)">
        <p class = "c-faq-list__title">What documents are required to get started on Sparrow?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[1].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[1].status == true}">
        <div class="c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text2">During user registration, a digital copy of your passport will be required for KYC/AML/CFT screening. For higher withdrawal limits, you may submit a request to our support team who will require a full-page scan of either a bank statement, or utility, cable, or cell phone bill for verification against your registered home address. The scanned document should clearly display your name and current residential address, and must have been issued no later than 90 days prior to the date of submission. If further verification is required, our support team may request additional documents.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(2)">
        <p class = "c-faq-list__title">Quick guide to identity verification and screening</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[2].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[2].status == true}">
        <div class = "c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text2">A guide to quick and painless identity verification (KYC/AML/CFT) on Sparrow</p><br />
          <p class = "c-faq-list__body-content-text_red">Note: Identification Document = ID = International Passport/National ID/Driver’s License</p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">You must be over 18 to register for an account</p>
          <p class = "c-faq-list__body-content-text2">Sparrow can only accept users above the age of 18. If you are under the age of 18, you will not pass identity verification.</p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Submit a valid ID</p>
          <p class = "c-faq-list__body-content-text2">Please submit a valid ID that is not expired. If your ID is past its expiry date, you will not pass identity verification.</p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Upload a brand new selfie. Do not upload a professional headshot, or cropped photo from your ID</p>
          <p class = "c-faq-list__body-content-text2">Please take and upload a brand new selfie. It should be a clear image of your entire face. Do not use a professional headshot or a cropped image from an ID. If you crop a photo from an ID and submit that as a selfie, it will not be accepted for verification. Submit a new selfie to avoid the most common issue with identity verification.</p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Select the correct ID type</p>
          <p class = "c-faq-list__body-content-text2">Select the correct document type that you want to use. For example, if you select a passport for your ID type, please make sure you upload a photo of your passport. We have seen many cases of users selecting a passport for the ID type when they actually want to use a driver’s license and fail verification.</p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Upload the correct images as instructed</p>
          <p class = "c-faq-list__body-content-text2">Carefully follow the instructions of what images are required. The instructions may ask for an image of the front only, or both the front and back, depending on the ID. For passports, take a photo of the entire passport, and not just the identity information page. If you upload a cropped information page, the system will reject the upload. We need to see the entire passport opened up.</p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Upload supported ID types</p>
          <p class = "c-faq-list__body-content-text2">Please upload an ID that is supported by our verification system. The type of identification accepted may vary depending on the country. The fastest and most reliable option is to use your international passport.</p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Submit an image that is legible, unaltered, undamaged, and free from glare</p>
          <p class = "c-faq-list__body-content-text2">Please ensure that the image of the ID is clear. If an image is illegible, altered, or damaged, it will not be accepted for verification. Do not conceal any information or add any marks or annotations to the image. The images submitted must be unaltered. Another common reason for verification failure is glare from a hologram or plastic cover. Please ensure there is no glare in the images submitted.</p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Submit IDs with Latin (English) characters for faster identity verifications</p>
          <p class = "c-faq-list__body-content-text2">For the fastest identity verification, please use international passports as these documents support Latin characters. </p><br />
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Sparrow’s commitment to compliance</p>
          <p class = "c-faq-list__body-content-text2">We are committed to being a responsible digital asset platform and we comply with the rules and regulations of the Republic of Singapore. We seek your cooperation and understanding by helping us make Sparrow the best place to trade digital assets.</p><br />
          <p class = "c-faq-list__body-content-text2">If you encounter any issues with identity verification, please submit a ticket on the Sparrow Support site at: <span class="c-faq-list__body-content-text_url" @click="openLink('https://support.sparrowexchange.com/hc/en-us/requests/new')">https://support.sparrowexchange.com/hc/en-us/requests/new</span></p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(3)">
        <p class = "c-faq-list__title">How do I deposit into my Sparrow account?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[3].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[3].status == true}">
        <div class="c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text2">To deposit funds into Sparrow, you will need to obtain a unique deposit address before initiating any transfer from your personal wallet.</p><br />
          <p class = "c-faq-list__body-content-text2">The unique address can be obtained via Sparrow Website > Funds > Deposit page.</p><br />
          <p class = "c-faq-list__body-content-text2">Sparrow currently accepts the following cryptocurrencies: Bitcoin (BTC), Ethereum (ETH), Tether (USDT ERC20), TrueUSD (TUSD), USD Coin (USDC), Paxos Standard Token (PAX), Stellar Lumens (XLM), Ripple (XRP).</p><br />
          <p class = "c-faq-list__body-content-text2">Sparrow does not currently accept FIAT deposits (USD, JPY, etc.), trades, or transactions.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(4)">
        <p class = "c-faq-list__title">How do I find my Sparrow ID?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[4].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[4].status == true}">
        <div class = "c-faq-list__body-content">
          <p class = "c-faq-list__body-content-text"><span class="c-faq-list__body-content-text_bold">Step 1: </span>Go to <span class="c-faq-list__body-content-text_url" @click="openLink('https://www.sparrowexchange.com')">www.sparrowexchange.com</span></p>
          <p class = "c-faq-list__body-content-text"><span class="c-faq-list__body-content-text_bold">Step 2: </span>Register/login to your account</p>
          <p>&nbsp;</p>
          <img src="@/assets/images/faq-sparrowid-1.png" class="c-faq-list__body-content-img"/>
          <p class = "c-faq-list__body-content-text"><span class="c-faq-list__body-content-text_bold">Step 3: </span>Find your Sparrow User ID here (Under “My Profile”)</p>
          <img src="@/assets/images/faq-sparrowid-2.png" class="c-faq-list__body-content-img"/>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(5)">
        <p class = "c-faq-list__title">What is the fee structure trading on Sparrow?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[5].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[5].status == true}">
        <div class = "c-faq-list__body-content">
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Deposit Fees</p>
          <p class="c-faq-list__body-content-text">There are no fees for deposits.</p>
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Withdrawal Fees</p>
          <p class="c-faq-list__body-content-text">Withdrawal fees will be regularly adjusted according to blockchain conditions.</p>
          <p class="c-faq-list__body-content-text">To see the latest withdrawal fees, please login to your account on the platform.</p>
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Option Transaction Fees</p>
          <p class="c-faq-list__body-content-text">We charge a transaction fee of either 0.1% of the notional value of the contract, or 30% of the Instant Premium, whichever is lower. We accept BTC, ETH or SP$ as payment for the transaction fee.</p>
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">ConvertNOW Facility (CNF) Transaction Fees </p>
          <p class="c-faq-list__body-content-text">We do not charge any transaction fees for ConvertNOW Facility (CNF).</p>
          <p class="c-faq-list__body-content-text c-faq-list__body-content-text_bold c-faq-list__body-content-text_underline">Discounted Transaction Fees</p>
          <img src="@/assets/images/faq-fees-1.png" class="c-faq-list__body-content-img c-faq-list__body-content-img_fees-small"/>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(6)">
        <p class = "c-faq-list__title">How do I withdraw funds from my Sparrow account?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[6].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[6].status == true}">
        <div class = "c-faq-list__body-content">
          <p class="c-faq-list__body-content-text">You may submit a withdrawal request at Sparrow Website > Funds > Withdrawal.</p>
          <p class="c-faq-list__body-content-text">You may make a withdrawal based on a daily, weekly or monthly limit (USD).</p>
          <p class="c-faq-list__body-content-text">For higher withdrawal limits, you may submit a request to our support team. Withdrawal requests are processed once you respond to the confirmation email.</p>
          <p class="c-faq-list__body-content-text">Please note that as an added security precaution, we keep only a small percentage of assets in hot storage, so a large withdrawal may take longer to process.</p>
          <p class="c-faq-list__body-content-text">If you wish to make a withdrawal that is significantly larger than your current withdrawal limit, you may submit a request to our support team.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(7)">
        <p class = "c-faq-list__title">Why did I not receive a verification email from Sparrow?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[7].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[7].status == true}">
        <div class = "c-faq-list__body-content">
          <p class="c-faq-list__body-content-text">If you have registered on Sparrow Platform recently and have yet to received any verification email, please follow the steps below:</p>
          <p class="c-faq-list__body-content-text">1. Check that you have provided the correct email address for registration.</p>
          <p class="c-faq-list__body-content-text">2. Check the spam folder of the provided email address.</p>
          <p class="c-faq-list__body-content-text">3. Whitelist Sparrow's email addresses in your email service provider.</p>
          <p class="c-faq-list__body-content-text">If you have completed the the steps above and have not received a verification email, please contact us directly at <span class="c-faq-list__body-content-text_url" @click="mailTo('support@sparrowexchange.com')">support@sparrowexchange.com</span>.</p>
          <p class="c-faq-list__body-content-text">Note: Sparrow will never request from you any personal, security, or transaction information via email.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(8)">
        <p class = "c-faq-list__title">Why am I unable to trade despite registering on Sparrow with my registered email address?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[8].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[8].status == true}">
        <div class="c-faq-list__body-content">
          <p class="c-faq-list__body-content-text2">After registering your email with Sparrow, you will be allowed to have a look around the platform and get familiar with it.</p><br />
          <p class="c-faq-list__body-content-text2">However, to begin trading, you will need to verify your identity which involves KYC/AML/CFT screening. This can be initiated by going to your Profile > Identity Verification.</p><br />
          <p class="c-faq-list__body-content-text2">If you have successfully verified your identity, but you are still unable to trade on our platform, please contact our support team via our Support Page.</p><br />
          <p class="c-faq-list__body-content-text2">Our support team will look into the matter immediately.</p>
        </div>
      </div>
    </div>
    <div class = "c-faq-list__item">
      <div class = "c-faq-list__head" @click = "toggleActive(9)">
        <p class = "c-faq-list__title">What should I do if I find transaction errors?</p>
        <img class = "c-faq-list__icon" src="@/assets/images/icon-plus.svg" v-if ="activeFaqList[9].status == false">
        <img class = "c-faq-list__icon" src="@/assets/images/icon-minus.svg" v-else>
      </div>
      <div class = "c-faq-list__body" :class="{'c-faq-list__body_active':activeFaqList[9].status == true}">
        <div class="c-faq-list__body-content">
          <p class="c-faq-list__body-content-text2">If you encounter any transactions you consider incorrect, please take note of the transaction ID and contact our support team via our Support Page.</p>
        </div>
      </div>
    </div>
    <div class="c-faq-list__body-content-margin-top30">
      <p><span class="c-faq-list__body-content-text_url" @click="openLink('https://support.sparrowexchange.com/hc/en-us')">See all</span> for full list of FAQ</p>
    </div>
  </div>
</div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'FAQ',
  data() {
    return {
      activeFaq: -1,
      offsetList: [],
      statusOpened: false,
      activeFaqList: [ {status:false}, {status:false}, {status:false}, {status:false}, {status:false}, {status:false}, {status:false}, {status:false}, {status:false}, {status:false} ]
    }
  },
  methods: {
    toggleActive:function(idx){
      let _this = this;
      if(this.activeFaqList[idx].status == true) {
        this.activeFaqList[idx].status = false
        this.statusOpened = false
      } else {
        this.statusOpened = true
        this.activeFaqList[idx].status = true
      }
      this.$nextTick(()=>{
        _this.adjustHeight(idx);
      })
    },
    adjustHeight:function(idx){
      let bodyElements = document.getElementsByClassName('c-faq-list__body')
      if(bodyElements[idx].classList.contains('c-faq-list__body_active')){
        let bodyHeight = bodyElements[idx].scrollHeight + 70
        bodyElements[idx].style.maxHeight = bodyHeight+'px'
        this.scrollToFAQ(bodyElements[idx].offsetTop);
      }
      else{
        bodyElements[idx].style.maxHeight = '0px'
      }
    },
    scrollToFAQ(position) {
      $('html,body').animate({scrollTop: position - 170},'slow');
    }
  },
}
</script>
